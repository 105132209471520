import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import TabArchives from "react-bootstrap/Tab";
import TabsTabArchives from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import "./petitionspage.css";
import { Link } from "react-router-dom";
import APIService from '../../APIService';

export default function Petitionpage() {

  const [petitions, setPetitions] = useState([]);
  const [otherPetitions, setOtherPetitions] = useState([]);

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [filterLicensee, setFilterLicensee] = useState("");

  const [filteredPetition, setFilteredPetition] = useState([]);
  const [filteredOtherPetition, setFilteredOtherPetition] = useState([]);

  const [licensees, setLicensees] = useState([]);

  // Utility function to generate years dynamically
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year.toString());
    }
    return years;
  }

  useEffect(() => {
    APIService.get('licensees').then((response) => {
      setLicensees(response.data);
    })
  }, [])


  useEffect(() => {
    APIService.get('petitions')
      .then((response) => {
        setPetitions(response.petitions);
        setFilteredPetition(response.petitions);
      })
  }, []);



  useEffect(() => {
    APIService.get('otherpetitions')
      .then((response) => {
        setOtherPetitions(response.petitions);
        setFilteredOtherPetition(response.petitions);
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    // filter the items using the apiUsers state
    var filteredItems = petitions.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPetition(filteredItems);

    // filter the items using the apiUsers state
    var filteredItems = otherPetitions.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOtherPetition(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    // filter the items using the apiUsers state
    var filteredItems = petitions.filter((data) =>
      data.date.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPetition(filteredItems);

    // filter the items using the apiUsers state
    var filteredItems = otherPetitions.filter((data) =>
      data.date.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOtherPetition(filteredItems);
  }

  const handleLicenseeFilterChange = (e) => {
    const searchTerm = e.target.value;
    // console.log(searchTerm);
    setFilterLicensee(searchTerm);

    // Filter the items using Licensees for 'petitions'
    var filteredPetitions = petitions.filter((data) =>
      data.licensee_id == searchTerm
    );
    setFilteredPetition(filteredPetitions);

    // Filter the items using Licensees for 'otherPetitions'
    var filteredOtherPetitions = otherPetitions.filter((data) =>
      data.licensee_id == searchTerm
    );
    setFilteredOtherPetition(filteredOtherPetitions);
  }

  return (
    <div>
      <Container fluid className="right-to-information">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">Petitions</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{marginTop:0}} id="petition" className="petition">
        <div id="petition-btn">
          <div className='pulse2'>
            <p className='blink-hard2'> new</p>
          </div>
          <Link to="/e-filling">
            {" "}
            <button type="button" className="btn btn-primary btn-lg ptn-btn" id="e-filling-btn">
              Petition e-filing
            </button>
          </Link>
        </div>
      </Container>

      <Container className='mt-1'  >
        <Row className="petition-search-area" id="petition-search-area">
          <Col className='mt-2' sm={6} md={3} lg={3} id="year-tab">
           
              <Form.Select
                aria-label="Floating label select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {generateYears().map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </Form.Select>
           
          </Col>

          <Col  className='mt-2' sm={6} md={3} lg={3} id="licence-bar1">
           
              <Form.Select
                aria-label="Default select example"
                value={filterLicensee}
                onChange={handleLicenseeFilterChange}
              >
                <option value="">Select Licensee</option>
                {licensees.map((licensee, index) => (
                  <option key={index} value={licensee.id}>
                    {licensee.heading}
                  </option>
                ))}
              </Form.Select>
          

          </Col  >
          <Col   sm={12} md={3} lg={3} className='form-aling mt-2'>
            <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange} />
            </FloatingLabelSerch>
            
          </Col>
          {/* <button type="button" className="btn btn-outline-primary " id='search-btn'>Search</button> */}
          <Col  className='mt-2' md={3} lg={3}  id='search-btn1' > <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
        </Row >
      </Container >

      <Container style={{marginTop:'10px'}} className="Archives">
        <Row className="wrapper">
          <Col lg={12}>
            <TabsTabArchives
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              <TabArchives eventKey="home" title="ARR Petitions">
                <div className="archive-scroll-container">
                  <Table striped bordered hover variant="light" className="archive-table">
                    <thead>
                      <tr>
                        <th style={{wordWrap:"break-word"}} className="table-no-aling">Sl No</th>
                        <th style={{width:'150px',wordWrap:"break-word"}}>OP No.</th>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredPetition
                        .map((petition, index) => (
                          <tr key={"key_" + index}>
                            <td style={{textAlign:'center'}}>{index + 1}</td>
                            <td>{petition.opno}</td>
                            <td>{petition.title}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{petition.date}</td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={petition.file} target='blank'>view</Link>
                              </div>
                            </td>
                          </tr>
                        ))}

                    </tbody>
                  </Table>
                </div>
              </TabArchives>

              <TabArchives eventKey="Other Petitions" title="Other Petitions">
                <div className="archive-scroll-container">
                  <Table striped bordered hover variant="light" className="archive-table">
                    <thead>
                      <tr>
                        <th style={{wordWrap:"inherit"}} className="table-no-aling">Sl No</th>
                        <th style={{width:'150px',wordWrap:"break-word"}}>OP No.</th>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredOtherPetition
                        .map((petition, index) => (
                          <tr key={"key_" + index}>
                            <td style={{textAlign:'center'}}>{index + 1}</td>
                            <td>{petition.opno}</td>
                            <td>{petition.title}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{petition.date}</td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={petition.file} target='blank'>view</Link>
                              </div>
                            </td>
                          </tr>
                        ))}

                    </tbody>
                  </Table>
                </div>
              </TabArchives>
            </TabsTabArchives>
          </Col>
        </Row>
      </Container>
    </div >
  );
}
