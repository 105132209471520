import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import './licensees.css';
import APIService from '../../APIService';

export default function () {

  const [data, setData] = useState([]);

  useEffect(() => {
    APIService.get('licensees')
      .then((response) => {
        // console.log(response.data);
        setData(response.data);
      })
  }, []);

  return (
    <div style={{marginBottom:"30px"}}>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Licensees</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='licensee-top mt-5'>
          {data.map((data, index) => (
            <Col md={4} key={index}>
              <div className='smart-city-aling' key={data.id}>
                <img className='licensee-img' src={data.image} />
                <h5 className='smart-city-head'>{data.heading}</h5>
                <p className='smart-city-text' >
                  {data.address}
                  <br />
                  <span className='licensee-contact-text'> Ph: {data.phone}</span>
                  <br />
                  <span className='licensee-contact-text' >Fax: {data.fax}</span>
                  <br />
                  <span className='licensee-contact-text' >E-mail: {data.email}</span>
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
