import React, { useState, useEffect } from 'react';
import './linktabs.css';
import { Fragment } from "react";
import { Link } from "react-router-dom";
export const LinkTabs = () => {
  return (
    <div style={{marginTop:'20px'}} className='container'>
      <div className="link-tabs">
        <Link to="/acts">
          <div className="link-box">
            <div>
              Acts
            </div>
          </div>
        </Link>
        <Link to="/policies">
          <div className="link-box">
            <div>
              Policies
            </div>
          </div>
        </Link>
        <Link to="/rules">
          <div className="link-box">
            <div>
              Rules
            </div>
          </div>
        </Link>
        <Link to="/advice-to-gok">
          <div className="link-box">
            <div>
              Advice to GoK
            </div>
          </div>
        </Link>
        <Link to="/repealed-regulations">
          <div className="link-box">
            <div>
              Repealed Regulations
            </div>
          </div>
        </Link>
        <Link to="/other-ercs">
          <div className="link-box">
            <div>
              Other ERCs
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
