import React, { useState, useEffect } from 'react';
import './archives.css';
import { Link } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import FloatingLabelSearch from 'react-bootstrap/FloatingLabel';
import APIService from '../../APIService';

export default function Archives() {

  const archiveTypes = [
    "Daily Orders",
    "General Order",
    "Tariff Orders",
    "ARR Orders",
    "Truing up Orders",
    "ARR Petitions",
    "Other Petitions",
  ];

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    APIService.get('archives')
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = data.filter((archive) =>
      archive.order.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    const filteredItems = data.filter((archive) =>
      archive.added_on.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  // Function to generate years from current year to 2001
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year);
    }
    return years;
  }

  return (
    <div>
      <Container fluid className='right-to-information'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Archives</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{marginTop:'20px'}}>
        <Row className='wrapper'>
          <Col className='mt-2' md={4} id="archives-search-area">
            
              <Form.Select
                aria-label="Default select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {generateYears().map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </Form.Select>
            
          </Col>
          <Col md={4} className='form-aling mt-2' id='archives-form'>
            <FloatingLabelSearch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange}
              />
            </FloatingLabelSearch>
            {/* <button type="button" className="btn btn-outline-primary" id='search-btn'>Search</button> */}
          </Col>
          <Col className='mt-2' md={4} id='search-btn1'  style={{textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
        </Row>
      </Container>

      <Container style={{marginTop:'10px'}}  className='Archives' id="archive-container">
        <Row className='content-table'>
          <Col md={12} >

            <Tabs
              defaultActiveKey={archiveTypes[0]}
              transition={false}
              id="noanim-tab-example"
              className="mb-3"
            >
              {archiveTypes.map((type, index) => (

                <Tab
                  eventKey={type}
                  title={type}
                  key={"tab_" + index}
                >
                  <div >
                    <Table striped bordered hover variant="light">
                      <thead>
                        <tr>
                          <th style={{width:'50px'}} className='table-no-aling'>Sl No</th>
                          <th>Order</th>
                          <th style={{width:'100px'}}>Date</th>
                          <th style={{textAlign:"center"}}>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData
                          .filter(archive => archive.option === type)
                          .map((archive, index) => (
                            <tr key={archive.id}>
                              <td style={{textAlign:"center"}} className='table-no-aling'>{index + 1}</td>
                              <td>{archive.order}</td>
                              <td>{archive.added_on}</td>
                              <td>
                                <div className='ordrs-icon-aling'>
                                  <Link to={archive.file}>View</Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
              ))}
            </Tabs>

          </Col>
        </Row>
      </Container>
    </div>
  )
}
