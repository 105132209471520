// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import TabArchives from 'react-bootstrap/Tab';
// import TabsTabArchives from 'react-bootstrap/Tabs';
// import Table from 'react-bootstrap/Table';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
// import './regulationspage.css';
// import { Link } from "react-router-dom";
// import APIService from '../../APIService';

// export default function Regulationspage() {

//   const regulationTypes = [
//     "Tariff Regulations",
//     "Conduct of Business Regulations",
//     "CGRF and Ombudsman Regulations",
//     "Licensing Regulations",
//     "Renewable Energy Regulations",
//     "Distribution Licensee Regulations",
//     "Generating companies Regulations",
//     "Connectivity and Open Access Regulations",
//     "Electricity Supply Code and Amendments",
//     "Electricity Grid Code and Amendments",
//     "Compliance and Audit Regulations",
//     "Administrative Regulations",
//     "Miscellaneous Regulations",
//   ];

//   const [draftData, setDraftData] = useState([]);
//   const [finaltData, setFinaltData] = useState([]);

//   const [filteredData, setFilteredData] = useState([]);
//   const [filteredFinalData, setFilteredFinalData] = useState([]);

//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");

//   const [filterYearFinal, setFilterYearFinal] = useState("");
//   const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

//   useEffect(() => {
//     APIService.get('draft-regulations')
//       .then((response) => {
//         setDraftData(response.data);
//         setFilteredData(response.data);
//       })
//   }, []);

//   useEffect(() => {
//     APIService.get('final-regulations')
//       .then((response) => {
//         setFinaltData(response.data);
//         setFilteredFinalData(response.data);
//       })
//   }, []);

//   const handleSearchInputChange = (e) => {
//     const searchTerm = e.target.value;
//     setSearchItem(searchTerm);
//     const filteredItems = draftData.filter((data) =>
//       data.description.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredData(filteredItems);
//   }

//   const handleYearFilterChange = (e) => {
//     const searchTerm = e.target.value;
//     setFilterYear(searchTerm);
//     const filteredItems = draftData.filter((data) =>
//       data.year.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredData(filteredItems);
//   }

//   const handleYearFilterChangeFinal = (e) => {
//     const searchTerm = e.target.value;
//     setFilterYearFinal(searchTerm);
//     const filteredItems = finaltData.filter((data) =>
//       data.year.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredFinalData(filteredItems);
//   }

//   const handleFinalRegulationTypeChange = (e) => {
//     const selectedType = e.target.value;
//     setSelectedFinalRegulationType(selectedType);
//     const filteredItems = selectedType === "All Regulations"
//       ? finaltData
//       : finaltData.filter((data) => data.option === selectedType);
//     setFilteredFinalData(filteredItems);
//   }

//   // Utility function to generate years dynamically
//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year.toString());
//     }
//     return years;
//   }

//   return (
//     <div>
//       <Container fluid className='right-to-information'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Regulations</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className='Regulations'>
//         <Row className='mt-4'>
//           <Col md={12}>
//             <TabsTabArchives
//               defaultActiveKey="home"
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3">
//               <TabArchives eventKey="home" title="  Draft Regulations">
//                 <div className='archive-scroll'>
//                   <div className='draft-regulation-search-area'>
//                     <Row className='reg-row' id='regulation-row'>
//                       <Col md={4} id="year-tab1">
//                         <FloatingLabel
//                           controlId="floatingSelectGrid"
//                           label="">
//                           <Form.Select
//                             aria-label="Default select example"
//                             value={filterYear}
//                             onChange={handleYearFilterChange}
//                           >
//                             <option value="">Year</option>
//                             {generateYears().map((year, index) => (
//                               <option key={index} value={year}>{year}</option>
//                             ))}
//                           </Form.Select>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} className='form-aling' id='reg-form-align'>
//                         <FloatingLabelSerch className='Search-aling1' controlId="floatingInputGrid" label="Keyword Search..">
//                           <Form.Control type="text"
//                             placeholder="Search"
//                             value={searchItem}
//                             onChange={handleSearchInputChange}
//                           />
//                         </FloatingLabelSerch>
//                         {/* <button type="button" className="btn btn-outline-primary" id='search-btn-reg1'>Search</button> */}
//                       </Col>
//                       <Col md={4} id='search-btn1'  style={{width:'250px' ,textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
//                     </Row>
//                   </div>
//                   <div className='overflow-table'>
//                     <Table striped bordered hover variant="light" id='draft-regulation-table'>
//                       <thead>
//                         <tr>
//                           <th className='table-no-aling'>SI.No. </th>
//                           <th>Description</th>
//                           <th>Year</th>
//                           <th>English</th>
//                           <th>Malayalam</th>
//                           <th>Gazatte</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {filteredData.map((data, index) => (
//                           <tr key={data.id}>
//                             <td className='table-no-aling' >{index + 1}</td>
//                             <td>{data.description}</td>
//                             <td>{data.year}</td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.english} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.malayalam} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.gazette} target='blank'>view</Link>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </div>
//               </TabArchives>

//               <TabArchives eventKey="Draft Regulations" title="Final Regulations">
//                 <div className='dropdown-wrapper'>
//                   <Row>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Regulation Type</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState" >
//                       <Form.Select id='dropdown-area'
//                         aria-label="Default select example"
//                         value={selectedFinalRegulationType}
//                         onChange={handleFinalRegulationTypeChange}
//                       >
//                         <option>All Regulations</option>
//                         {regulationTypes.map((type, index) => (
//                           <option key={index}>{type}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>

//                   <Row id='regulation-year'>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Year</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState" >
//                       <Form.Select id='dropdown-year'
//                         aria-label="Default select example"
//                         value={filterYearFinal}
//                         onChange={handleYearFilterChangeFinal}
//                       >
//                         <option>Choose...</option>
//                         {generateYears().map((year, index) => (
//                           <option key={index} value={year}>{year}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>
//                   <button type="button" className="btn btn-outline-primary" id='search-btn-finalreg'>Submit</button>
//                 </div>

//                 {regulationTypes.map((type, index) => (
//                   <div className='final-reg-table' key={"div_" + index} >
//                     <h3 id='table-heading-final-regulations'>{type}</h3>
//                     <div className='table-responsive'>
//                       <Table striped bordered hover variant="light">
//                         <thead>
//                           <tr>
//                             <th className='table-no-aling'>SL.No.</th>
//                             <th>Description</th>
//                             <th>Year</th>
//                             <th>English</th>
//                             <th>Malayalam</th>
//                             <th>Gazatte</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {filteredFinalData
//                             .filter(data => data.option === type)
//                             .map((data, index) => (
//                               <tr key={data.id}>
//                                 <td className='table-no-aling' >{index + 1}</td>
//                                 <td>{data.description}</td>
//                                 <td>{data.year}</td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.english} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.malayalam} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.gazette} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                               </tr>
//                             ))}
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 ))}
//               </TabArchives>
//             </TabsTabArchives>
//           </Col>
//         </Row>
//       </div>
//     </div >
//   )
// }
// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import TabArchives from 'react-bootstrap/Tab';
// import TabsTabArchives from 'react-bootstrap/Tabs';
// import Table from 'react-bootstrap/Table';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
// import './regulationspage.css';
// import { Link } from "react-router-dom";
// import APIService from '../../APIService';

// export default function Regulationspage() {

//   const regulationTypes = [
//     "Tariff Regulations",
//     "Conduct of Business Regulations",
//     "CGRF and Ombudsman Regulations",
//     "Licensing Regulations",
//     "Renewable Energy Regulations",
//     "Distribution Licensee Regulations",
//     "Generating companies Regulations",
//     "Connectivity and Open Access Regulations",
//     "Electricity Supply Code and Amendments",
//     "Electricity Grid Code and Amendments",
//     "Compliance and Audit Regulations",
//     "Administrative Regulations",
//     "Miscellaneous Regulations",
//   ];

//   const [draftData, setDraftData] = useState([]);
//   const [finaltData, setFinaltData] = useState([]);

//   const [filteredData, setFilteredData] = useState([]);
//   const [filteredFinalData, setFilteredFinalData] = useState([]);

//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");

//   const [filterYearFinal, setFilterYearFinal] = useState("");
//   const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

//   useEffect(() => {
//     APIService.get('draft-regulations')
//       .then((response) => {
//         setDraftData(response.data);
//         setFilteredData(response.data);
//       })
//   }, []);

//   useEffect(() => {
//     APIService.get('final-regulations')
//       .then((response) => {
//         setFinaltData(response.data);
//         setFilteredFinalData(response.data);
//       })
//   }, []);

//   useEffect(() => {
//     const filterDraftData = () => {
//       const searchTerm = searchItem.toLowerCase();
//       const filteredItems = draftData.filter((data) =>
//         data.description.toLowerCase().includes(searchTerm) &&
//         data.year.toLowerCase().includes(filterYear)
//       );
//       setFilteredData(filteredItems);
//     };

//     filterDraftData();
//   }, [searchItem, filterYear, draftData]);

//   useEffect(() => {
//     const filterFinalData = () => {
//       const filteredItems = finaltData.filter((data) =>
//         (selectedFinalRegulationType === "All Regulations" || data.option === selectedFinalRegulationType) &&
//         (filterYearFinal === "" || data.year === filterYearFinal)
//       );
//       setFilteredFinalData(filteredItems);
//     };

//     filterFinalData();
//   }, [selectedFinalRegulationType, filterYearFinal, finaltData]);

//   const handleSearchInputChange = (e) => {
//     setSearchItem(e.target.value);
//   }

//   const handleYearFilterChange = (e) => {
//     setFilterYear(e.target.value);
//   }

//   const handleYearFilterChangeFinal = (e) => {
//     setFilterYearFinal(e.target.value);
//   }

//   const handleFinalRegulationTypeChange = (e) => {
//     setSelectedFinalRegulationType(e.target.value);
//   }

//   // Utility function to generate years dynamically
//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year.toString());
//     }
//     return years;
//   }

//   return (
//     <div>
//       <Container fluid className='right-to-information'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Regulations</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className='Regulations'>
//         <Row className='mt-4'>
//           <Col md={12}>
//             <TabsTabArchives
//               defaultActiveKey="home"
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3">
//               <TabArchives eventKey="home" title="Draft Regulations">
//                 <div className='archive-scroll'>
//                   <div className='draft-regulation-search-area'>
//                     <Row className='reg-row' id='regulation-row'>
//                       <Col md={4} id="year-tab1">
//                         <FloatingLabel
//                           controlId="floatingSelectGrid"
//                           label="">
//                           <Form.Select
//                             aria-label="Default select example"
//                             value={filterYear}
//                             onChange={handleYearFilterChange}
//                           >
//                             <option value="">Year</option>
//                             {generateYears().map((year, index) => (
//                               <option key={index} value={year}>{year}</option>
//                             ))}
//                           </Form.Select>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} className='form-aling' id='reg-form-align'>
//                         <FloatingLabelSerch className='Search-aling1' controlId="floatingInputGrid" label="Keyword Search..">
//                           <Form.Control type="text"
//                             placeholder="Search"
//                             value={searchItem}
//                             onChange={handleSearchInputChange}
//                           />
//                         </FloatingLabelSerch>
//                       </Col>
//                       <Col md={4} id='search-btn1' style={{ width: '250px', textAlign: 'center' }}> <button style={{ width: '100%' }} type="button" className="btn btn-outline-primary " >Search</button></Col>
//                     </Row>
//                   </div>
//                   <div className='overflow-table'>
//                     <Table striped bordered hover variant="light" id='draft-regulation-table'>
//                       <thead>
//                         <tr>
//                           <th className='table-no-aling'>SI.No. </th>
//                           <th>Description</th>
//                           <th>Year</th>
//                           <th>English</th>
//                           <th>Malayalam</th>
//                           <th>Gazatte</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {filteredData.map((data, index) => (
//                           <tr key={data.id}>
//                             <td className='table-no-aling' >{index + 1}</td>
//                             <td style={{ color: data.color }}>{data.description}</td>
//                             <td>{data.year}</td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.english} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.malayalam} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.gazette} target='blank'>view</Link>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </div>
//               </TabArchives>

//               <TabArchives eventKey="Draft Regulations" title="Final Regulations">
//                 <div className='dropdown-wrapper'>
//                   <Row>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Regulation Type</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState" >
//                       <Form.Select id='dropdown-area'
//                         aria-label="Default select example"
//                         value={selectedFinalRegulationType}
//                         onChange={handleFinalRegulationTypeChange}
//                       >
//                         <option>All Regulations</option>
//                         {regulationTypes.map((type, index) => (
//                           <option key={index}>{type}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>

//                   <Row id='regulation-year'>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Year</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState" >
//                       <Form.Select id='dropdown-year'
//                         aria-label="Default select example"
//                         value={filterYearFinal}
//                         onChange={handleYearFilterChangeFinal}
//                       >
//                         <option value="">Choose...</option>
//                         {generateYears().map((year, index) => (
//                           <option key={index} value={year}>{year}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>
//                   <button type="button" className="btn btn-outline-primary" id='search-btn-finalreg'>Submit</button>
//                 </div>

//                 {regulationTypes.map((type, index) => (
//                   <div className='final-reg-table' key={"div_" + index} >
//                     <h3 id='table-heading-final-regulations'>{type}</h3>
//                     <div className='table-responsive'>
//                       <Table striped bordered hover variant="light">
//                         <thead>
//                           <tr>
//                             <th className='table-no-aling'>SL.No.</th>
//                             <th>Description</th>
//                             <th>Year</th>
//                             <th>English</th>
//                             <th>Malayalam</th>
//                             <th>Gazatte</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {filteredFinalData
//                             .filter(data => (selectedFinalRegulationType === "All Regulations" || data.option === type) &&
//                                             (filterYearFinal === "" || data.year === filterYearFinal))
//                             .map((data, index) => (
//                               <tr key={data.id}>
//                                 <td className='table-no-aling' >{index + 1}</td>
//                                 <td>{data.description}</td>
//                                 <td>{data.year}</td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.english} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.malayalam} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.gazette} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                               </tr>
//                             ))}
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 ))}
//               </TabArchives>
//             </TabsTabArchives>
//           </Col>
//         </Row>
//       </div>
//     </div >
//   )
// }
// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import TabArchives from 'react-bootstrap/Tab';
// import TabsTabArchives from 'react-bootstrap/Tabs';
// import Table from 'react-bootstrap/Table';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
// import './regulationspage.css';
// import { Link } from "react-router-dom";
// import APIService from '../../APIService';

// export default function Regulationspage() {

//   const regulationTypes = [
//     "Tariff Regulations",
//     "Conduct of Business Regulations",
//     "CGRF and Ombudsman Regulations",
//     "Licensing Regulations",
//     "Renewable Energy Regulations",
//     "Distribution Licensee Regulations",
//     "Generating companies Regulations",
//     "Connectivity and Open Access Regulations",
//     "Electricity Supply Code and Amendments",
//     "Electricity Grid Code and Amendments",
//     "Compliance and Audit Regulations",
//     "Administrative Regulations",
//     "Miscellaneous Regulations",
//   ];

//   const [draftData, setDraftData] = useState([]);
//   const [finalData, setFinalData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [filteredFinalData, setFilteredFinalData] = useState([]);
//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");
//   const [filterYearFinal, setFilterYearFinal] = useState("");
//   const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

//   useEffect(() => {
//     APIService.get('draft-regulations')
//       .then((response) => {
//         setDraftData(response.data);
//         setFilteredData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     APIService.get('final-regulations')
//       .then((response) => {
//         setFinalData(response.data);
//         setFilteredFinalData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     const filterDraftData = () => {
//       const searchTerm = searchItem.toLowerCase();
//       const filteredItems = draftData.filter((data) =>
//         data.description.toLowerCase().includes(searchTerm) &&
//         data.year.toLowerCase().includes(filterYear)
//       );
//       setFilteredData(filteredItems);
//     };
//     filterDraftData();
//   }, [searchItem, filterYear, draftData]);

//   useEffect(() => {
//     const filterFinalData = () => {
//       const filteredItems = finalData.filter((data) =>
//         (selectedFinalRegulationType === "All Regulations" || data.option === selectedFinalRegulationType) &&
//         (filterYearFinal === "" || data.year === filterYearFinal)
//       );
//       setFilteredFinalData(filteredItems);
//     };
//     filterFinalData();
//   }, [selectedFinalRegulationType, filterYearFinal, finalData]);

//   const handleSearchInputChange = (e) => {
//     setSearchItem(e.target.value);
//   }

//   const handleYearFilterChange = (e) => {
//     setFilterYear(e.target.value);
//   }

//   const handleYearFilterChangeFinal = (e) => {
//     setFilterYearFinal(e.target.value);
//   }

//   const handleFinalRegulationTypeChange = (e) => {
//     setSelectedFinalRegulationType(e.target.value);
//   }

//   // Utility function to generate years dynamically
//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year.toString());
//     }
//     return years;
//   }

//   return (
//     <div>
//       <Container fluid className='right-to-information'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Regulations</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className='Regulations'>
//         <Row className='mt-4'>
//           <Col md={12}>
//             <TabsTabArchives
//               defaultActiveKey="home"
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3">
//               <TabArchives eventKey="home" title="Draft Regulations">
//                 <div className='archive-scroll'>
//                   <div className='draft-regulation-search-area'>
//                     <Row className='reg-row' id='regulation-row'>
//                       <Col md={4} id="year-tab1">
//                         <FloatingLabel controlId="floatingSelectGrid" label="">
//                           <Form.Select
//                             aria-label="Default select example"
//                             value={filterYear}
//                             onChange={handleYearFilterChange}
//                           >
//                             <option value="">Year</option>
//                             {generateYears().map((year, index) => (
//                               <option key={index} value={year}>{year}</option>
//                             ))}
//                           </Form.Select>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} className='form-aling' id='reg-form-align'>
//                         <FloatingLabelSerch className='Search-aling1' controlId="floatingInputGrid" label="Keyword Search..">
//                           <Form.Control type="text"
//                             placeholder="Search"
//                             value={searchItem}
//                             onChange={handleSearchInputChange}
//                           />
//                         </FloatingLabelSerch>
//                       </Col>
//                       <Col md={4} id='search-btn1' style={{ width: '250px', textAlign: 'center' }}>
//                         <button style={{ width: '100%' }} type="button" className="btn btn-outline-primary">Search</button>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className='overflow-table'>
//                     <Table striped bordered hover variant="light" id='draft-regulation-table'>
//                       <thead>
//                         <tr>
//                           <th className='table-no-aling'>SI.No.</th>
//                           <th>Description</th>
//                           <th>Year</th>
//                           <th>English</th>
//                           <th>Malayalam</th>
//                           <th>Gazatte</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {filteredData.map((data, index) => (
//                           <tr key={data.id}>
//                             <td className='table-no-aling'>{index + 1}</td>
//                             <td style={{ color: data.color }}>{data.description}</td>
//                             <td>{data.year}</td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.english} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.malayalam} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.gazette} target='blank'>view</Link>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </div>
//               </TabArchives>

//               <TabArchives eventKey="Draft Regulations" title="Final Regulations">
//                 <div className='dropdown-wrapper'>
//                   <Row>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Regulation Type</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select id='dropdown-area'
//                         aria-label="Default select example"
//                         value={selectedFinalRegulationType}
//                         onChange={handleFinalRegulationTypeChange}
//                       >
//                         <option>All Regulations</option>
//                         {regulationTypes.map((type, index) => (
//                           <option key={index}>{type}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>

//                   <Row id='regulation-year'>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Year</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select id='dropdown-year'
//                         aria-label="Default select example"
//                         value={filterYearFinal}
//                         onChange={handleYearFilterChangeFinal}
//                       >
//                         <option value="">Choose...</option>
//                         {generateYears().map((year, index) => (
//                           <option key={index} value={year}>{year}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>
//                   <button type="button" className="btn btn-outline-primary" id='search-btn-finalreg'>Submit</button>
//                 </div>

//                 {regulationTypes.map((type, index) => (
//                   <div className='final-reg-table' key={"div_" + index}>
//                     <h3 id='table-heading-final-regulations'>{type}</h3>
//                     <div className='table-responsive'>
//                       <Table striped bordered hover variant="light">
//                         <thead>
//                           <tr>
//                             <th className='table-no-aling'>SL.No.</th>
//                             <th>Description</th>
//                             <th>Year</th>
//                             <th>English</th>
//                             <th>Malayalam</th>
//                             <th>Gazatte</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {filteredFinalData
//                             .filter(data => (selectedFinalRegulationType === "All Regulations" || data.option === type) &&
//                                             (filterYearFinal === "" || data.year === filterYearFinal))
//                             .map((data, index) => (
//                               <tr key={data.id}>
//                                 <td className='table-no-aling'>{index + 1}</td>
//                                 <td dangerouslySetInnerHTML={{ __html: data.description }}></td>
//                                 <td>{data.year}</td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.english} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.malayalam} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.gazette} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                               </tr>
//                             ))}
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 ))}
//               </TabArchives>
//             </TabsTabArchives>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import TabArchives from 'react-bootstrap/Tab';
// import TabsTabArchives from 'react-bootstrap/Tabs';
// import Table from 'react-bootstrap/Table';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
// import './regulationspage.css';
// import { Link } from "react-router-dom";
// import APIService from '../../APIService';

// export default function Regulationspage() {

//   const regulationTypes = [
//     "Tariff Regulations",
//     "Conduct of Business Regulations",
//     "CGRF and Ombudsman Regulations",
//     "Licensing Regulations",
//     "Renewable Energy Regulations",
//     "Distribution Licensee Regulations",
//     "Generating companies Regulations",
//     "Connectivity and Open Access Regulations",
//     "Electricity Supply Code and Amendments",
//     "Electricity Grid Code and Amendments",
//     "Compliance and Audit Regulations",
//     "Administrative Regulations",
//     "Miscellaneous Regulations",
//   ];

//   const [draftData, setDraftData] = useState([]);
//   const [finalData, setFinalData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [filteredFinalData, setFilteredFinalData] = useState([]);
//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");
//   const [filterYearFinal, setFilterYearFinal] = useState("");
//   const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

//   useEffect(() => {
//     APIService.get('draft-regulations')
//       .then((response) => {
//         setDraftData(response.data);
//         setFilteredData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     APIService.get('final-regulations')
//       .then((response) => {
//         setFinalData(response.data);
//         setFilteredFinalData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     const filterDraftData = () => {
//       const searchTerm = searchItem.toLowerCase();
//       const filteredItems = draftData.filter((data) =>
//         data.description.toLowerCase().includes(searchTerm) &&
//         data.year.toLowerCase().includes(filterYear)
//       );
//       setFilteredData(filteredItems);
//     };
//     filterDraftData();
//   }, [searchItem, filterYear, draftData]);

//   useEffect(() => {
//     const filterFinalData = () => {
//       const filteredItems = finalData.filter((data) =>
//         (selectedFinalRegulationType === "All Regulations" || data.option === selectedFinalRegulationType) &&
//         (filterYearFinal === "" || data.year === filterYearFinal)
//       ).sort((a, b) => b.year - a.year); // Sort by year in descending order
//       setFilteredFinalData(filteredItems);
//     };
//     filterFinalData();
//   }, [selectedFinalRegulationType, filterYearFinal, finalData]);

//   const handleSearchInputChange = (e) => {
//     setSearchItem(e.target.value);
//   }

//   const handleYearFilterChange = (e) => {
//     setFilterYear(e.target.value);
//   }

//   const handleYearFilterChangeFinal = (e) => {
//     setFilterYearFinal(e.target.value);
//   }

//   const handleFinalRegulationTypeChange = (e) => {
//     setSelectedFinalRegulationType(e.target.value);
//   }

//   // Utility function to generate years dynamically
//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year.toString());
//     }
//     return years;
//   }

//   return (
//     <div>
//       <Container fluid className='right-to-information'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Regulations</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className='Regulations'>
//         <Row className='mt-4'>
//           <Col md={12}>
//             <TabsTabArchives
//               defaultActiveKey="home"
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3">
//               <TabArchives eventKey="home" title="Draft Regulations">
//                 <div className='archive-scroll'>
//                   <div className='draft-regulation-search-area'>
//                     <Row className='reg-row' id='regulation-row'>
//                       <Col md={4} id="year-tab1">
//                         <FloatingLabel controlId="floatingSelectGrid" label="">
//                           <Form.Select
//                             aria-label="Default select example"
//                             value={filterYear}
//                             onChange={handleYearFilterChange}
//                           >
//                             <option value="">Year</option>
//                             {generateYears().map((year, index) => (
//                               <option key={index} value={year}>{year}</option>
//                             ))}
//                           </Form.Select>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} className='form-aling' id='reg-form-align'>
//                         <FloatingLabelSerch className='Search-aling1' controlId="floatingInputGrid" label="Keyword Search..">
//                           <Form.Control type="text"
//                             placeholder="Search"
//                             value={searchItem}
//                             onChange={handleSearchInputChange}
//                           />
//                         </FloatingLabelSerch>
//                       </Col>
//                       <Col md={4} id='search-btn1' style={{ width: '250px', textAlign: 'center' }}>
//                         <button style={{ width: '100%' }} type="button" className="btn btn-outline-primary">Search</button>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className='overflow-table'>
//                     <Table striped bordered hover variant="light" id='draft-regulation-table'>
//                       <thead>
//                         <tr>
//                           <th className='table-no-aling'>SI.No.</th>
//                           <th>Description</th>
//                           <th>Year</th>
//                           <th>English</th>
//                           <th>Malayalam</th>
//                           <th>Gazatte</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {filteredData.map((data, index) => (
//                           <tr key={data.id}>
//                             <td className='table-no-aling'>{index + 1}</td>
//                             <td style={{ color: data.color }}>{data.description}</td>
//                             <td>{data.year}</td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.english} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.malayalam} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.gazette} target='blank'>view</Link>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </div>
//               </TabArchives>

//               <TabArchives eventKey="Draft Regulations" title="Final Regulations">
//                 <div className='dropdown-wrapper'>
//                   <Row>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Regulation Type</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select id='dropdown-area'
//                         aria-label="Default select example"
//                         value={selectedFinalRegulationType}
//                         onChange={handleFinalRegulationTypeChange}
//                       >
//                         <option>All Regulations</option>
//                         {regulationTypes.map((type, index) => (
//                           <option key={index}>{type}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>

//                   <Row id='regulation-year'>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Year</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select id='dropdown-year'
//                         aria-label="Default select example"
//                         value={filterYearFinal}
//                         onChange={handleYearFilterChangeFinal}
//                       >
//                         <option value="">Choose...</option>
//                         {generateYears().map((year, index) => (
//                           <option key={index} value={year}>{year}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>
//                   <button type="button" className="btn btn-outline-primary" id='search-btn-finalreg'>Submit</button>
//                 </div>

//                 {regulationTypes.map((type, index) => (
//                   <div className='final-reg-table' key={"div_" + index}>
//                     <h3 id='table-heading-final-regulations'>{type}</h3>
//                     <div className='table-responsive'>
//                       <Table striped bordered hover variant="light">
//                         <thead>
//                           <tr>
//                             <th className='table-no-aling'>SL.No.</th>
//                             <th>Description</th>
//                             <th>Year</th>
//                             <th>English</th>
//                             <th>Malayalam</th>
//                             <th>Gazatte</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {filteredFinalData
//                             .filter(data => (selectedFinalRegulationType === "All Regulations" || data.option === type) &&
//                                             (filterYearFinal === "" || data.year === filterYearFinal))
//                             .sort((a, b) => b.year - a.year) // Sort by year in descending order
//                             .map((data, index) => (
//                               <tr key={data.id}>
//                                 <td className='table-no-aling'>{index + 1}</td>
//                                 <td dangerouslySetInnerHTML={{ __html: data.description }}></td>
//                                 <td>{data.year}</td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.english} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.malayalam} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.gazette} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                               </tr>
//                             ))}
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 ))}
//               </TabArchives>
//             </TabsTabArchives>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// }
// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import Table from 'react-bootstrap/Table';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import './regulationspage.css';
// import { Link } from "react-router-dom";
// import APIService from '../../APIService';

// export default function Regulationspage() {
//   const regulationTypes = [
//     "Tariff Regulations",
//     "Conduct of Business Regulations",
//     "CGRF and Ombudsman Regulations",
//     "Licensing Regulations",
//     "Renewable Energy Regulations",
//     "Distribution Licensee Regulations",
//     "Generating companies Regulations",
//     "Connectivity and Open Access Regulations",
//     "Electricity Supply Code and Amendments",
//     "Electricity Grid Code and Amendments",
//     "Compliance and Audit Regulations",
//     "Administrative Regulations",
//     "Miscellaneous Regulations",
//   ];

//   const [draftData, setDraftData] = useState([]);
//   const [finalData, setFinalData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [filteredFinalData, setFilteredFinalData] = useState([]);
//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");
//   const [filterYearFinal, setFilterYearFinal] = useState("");
//   const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

//   useEffect(() => {
//     APIService.get('draft-regulations')
//       .then((response) => {
//         // Assuming response.data includes a 'color' property for each draft regulation
//         setDraftData(response.data);
//         setFilteredData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     APIService.get('final-regulations')
//       .then((response) => {
//         setFinalData(response.data);
//         setFilteredFinalData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     const filterDraftData = () => {
//       const searchTerm = searchItem.toLowerCase();
//       const filteredItems = draftData.filter((data) =>
//         data.description.toLowerCase().includes(searchTerm) &&
//         data.year.toLowerCase().includes(filterYear)
//       );
//       setFilteredData(filteredItems);
//     };
//     filterDraftData();
//   }, [searchItem, filterYear, draftData]);

//   useEffect(() => {
//     const filterFinalData = () => {
//       const filteredItems = finalData.filter((data) =>
//         (selectedFinalRegulationType === "All Regulations" || data.option === selectedFinalRegulationType) &&
//         (filterYearFinal === "" || data.year === filterYearFinal)
//       ).sort((a, b) => b.year - a.year); // Sort by year in descending order
//       setFilteredFinalData(filteredItems);
//     };
//     filterFinalData();
//   }, [selectedFinalRegulationType, filterYearFinal, finalData]);

//   const handleSearchInputChange = (e) => {
//     setSearchItem(e.target.value);
//   }

//   const handleYearFilterChange = (e) => {
//     setFilterYear(e.target.value);
//   }

//   const handleYearFilterChangeFinal = (e) => {
//     setFilterYearFinal(e.target.value);
//   }

//   const handleFinalRegulationTypeChange = (e) => {
//     setSelectedFinalRegulationType(e.target.value);
//   }

//   // Utility function to generate years dynamically
//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year.toString());
//     }
//     return years;
//   }

//   return (
//     <div>
//       <Container fluid className='right-to-information'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Regulations</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className='Regulations'>
//         <Row className='mt-4'>
//           <Col md={12}>
//             <Tabs
//               defaultActiveKey="draft"
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3"
//             >
//               <Tab eventKey="draft" title="Draft Regulations">
//                 <div className='archive-scroll'>
//                   <div className='draft-regulation-search-area'>
//                     <Row className='reg-row' id='regulation-row'>
//                       <Col md={4} id="year-tab1">
//                         <FloatingLabel controlId="floatingSelectGrid" label="">
//                           <Form.Select
//                             aria-label="Default select example"
//                             value={filterYear}
//                             onChange={handleYearFilterChange}
//                           >
//                             <option value="">Year</option>
//                             {generateYears().map((year, index) => (
//                               <option key={index} value={year}>{year}</option>
//                             ))}
//                           </Form.Select>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} className='form-aling' id='reg-form-align'>
//                         <FloatingLabel controlId="floatingInputGrid" label="Keyword Search..">
//                           <Form.Control
//                             type="text"
//                             placeholder="Search"
//                             value={searchItem}
//                             onChange={handleSearchInputChange}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} id='search-btn1' style={{ width: '250px', textAlign: 'center' }}>
//                         <button style={{ width: '100%' }} type="button" className="btn btn-outline-primary">Search</button>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className='overflow-table'>
//                     <Table striped bordered hover variant="light" id='draft-regulation-table'>
//                       <thead>
//                         <tr>
//                           <th className='table-no-aling'>SI.No.</th>
//                           <th>Description</th>
//                           <th>Year</th>
//                           <th>English</th>
//                           <th>Malayalam</th>
//                           <th>Gazatte</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {filteredData.map((data, index) => (
//                           <tr key={data.id}>
//                             <td className='table-no-aling'>{index + 1}</td>
//                             <td style={{ color: data.color }} dangerouslySetInnerHTML={{ __html: data.description }}></td>
//                             <td>{data.year}</td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.english} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.malayalam} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.gazette} target='blank'>view</Link>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </div>
//               </Tab>

//               <Tab eventKey="final" title="Final Regulations">
//                 <div className='dropdown-wrapper'>
//                   <Row>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Regulation Type</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select
//                         aria-label="Default select example"
//                         value={selectedFinalRegulationType}
//                         onChange={handleFinalRegulationTypeChange}
//                       >
//                         <option>All Regulations</option>
//                         {regulationTypes.map((type, index) => (
//                           <option key={index}>{type}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>

//                   <Row id='regulation-year'>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Year</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select
//                         aria-label="Default select example"
//                         value={filterYearFinal}
//                         onChange={handleYearFilterChangeFinal}
//                       >
//                         <option value="">Choose...</option>
//                         {generateYears().map((year, index) => (
//                           <option key={index} value={year}>{year}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>
//                   <button type="button" className="btn btn-outline-primary" id='search-btn-finalreg'>Submit</button>
//                 </div>

//                 {regulationTypes.map((type, index) => (
//                   <div className='final-reg-table' key={"div_" + index}>
//                     <h3 id='table-heading-final-regulations'>{type}</h3>
//                     <div className='table-responsive'>
//                       <Table striped bordered hover variant="light">
//                         <thead>
//                           <tr>
//                             <th className='table-no-aling'>SL.No.</th>
//                             <th>Description</th>
//                             <th>Year</th>
//                             <th>English</th>
//                             <th>Malayalam</th>
//                             <th>Gazatte</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {filteredFinalData
//                             .filter(data => (selectedFinalRegulationType === "All Regulations" || data.option === type) &&
//                               (filterYearFinal === "" || data.year === filterYearFinal))
//                             .sort((a, b) => b.year - a.year) // Sort by year in descending order
//                             .map((data, index) => (
//                               <tr key={data.id}>
//                                 <td className='table-no-aling'>{index + 1}</td>
//                                 <td dangerouslySetInnerHTML={{ __html: data.description }}></td>
//                                 <td>{data.year}</td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.english} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.malayalam} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.gazette} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                               </tr>
//                             ))}
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 ))}
//               </Tab>
//             </Tabs>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import Table from 'react-bootstrap/Table';
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import { Link } from "react-router-dom";
// import APIService from '../../APIService';

// import './regulationspage.css'; // Ensure your existing CSS file is imported here

// export default function Regulationspage() {
//   const regulationTypes = [
//     "Tariff Regulations",
//     "Conduct of Business Regulations",
//     "CGRF and Ombudsman Regulations",
//     "Licensing Regulations",
//     "Renewable Energy Regulations",
//     "Distribution Licensee Regulations",
//     "Generating companies Regulations",
//     "Connectivity and Open Access Regulations",
//     "Electricity Supply Code and Amendments",
//     "Electricity Grid Code and Amendments",
//     "Compliance and Audit Regulations",
//     "Administrative Regulations",
//     "Miscellaneous Regulations",
//   ];

//   const [draftData, setDraftData] = useState([]);
//   const [finalData, setFinalData] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [filteredFinalData, setFilteredFinalData] = useState([]);
//   const [searchItem, setSearchItem] = useState("");
//   const [filterYear, setFilterYear] = useState("");
//   const [filterYearFinal, setFilterYearFinal] = useState("");
//   const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

//   useEffect(() => {
//     APIService.get('draft-regulations')
//       .then((response) => {
//         setDraftData(response.data);
//         setFilteredData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     APIService.get('final-regulations')
//       .then((response) => {
//         setFinalData(response.data);
//         setFilteredFinalData(response.data);
//       });
//   }, []);

//   useEffect(() => {
//     const filterDraftData = () => {
//       const searchTerm = searchItem.toLowerCase();
//       const filteredItems = draftData.filter((data) =>
//         data.description.toLowerCase().includes(searchTerm) &&
//         data.year.toLowerCase().includes(filterYear)
//       );
//       setFilteredData(filteredItems);
//     };
//     filterDraftData();
//   }, [searchItem, filterYear, draftData]);

//   useEffect(() => {
//     const filterFinalData = () => {
//       const filteredItems = finalData.filter((data) =>
//         (selectedFinalRegulationType === "All Regulations" || data.option === selectedFinalRegulationType) &&
//         (filterYearFinal === "" || data.year === filterYearFinal)
//       ).sort((a, b) => b.year - a.year);
//       setFilteredFinalData(filteredItems);
//     };
//     filterFinalData();
//   }, [selectedFinalRegulationType, filterYearFinal, finalData]);

//   const handleSearchInputChange = (e) => {
//     setSearchItem(e.target.value);
//   }

//   const handleYearFilterChange = (e) => {
//     setFilterYear(e.target.value);
//   }

//   const handleYearFilterChangeFinal = (e) => {
//     setFilterYearFinal(e.target.value);
//   }

//   const handleFinalRegulationTypeChange = (e) => {
//     setSelectedFinalRegulationType(e.target.value);
//   }

//   const generateYears = () => {
//     const currentYear = new Date().getFullYear();
//     const years = [];
//     for (let year = currentYear; year >= 2001; year--) {
//       years.push(year.toString());
//     }
//     return years;
//   }

//   return (
//     <div>
//       <Container fluid className='right-to-information'>
//         <Row>
//           <Col md={12}>
//             <div className="organisation-img">
//               <h1 className='organisation-head'>Regulations</h1>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//       <div className='Regulations'>
//         <Row className='mt-4'>
//           <Col md={12}>
//             <Tabs
//               defaultActiveKey="draft"
//               transition={false}
//               id="noanim-tab-example"
//               className="mb-3"
//             >
//               <Tab eventKey="draft" title="Draft Regulations">
//                 <div className='archive-scroll'>
//                   <div className='draft-regulation-search-area'>
//                     <Row className='reg-row' id='regulation-row'>
//                       <Col md={4} id="year-tab1">
//                         <FloatingLabel controlId="floatingSelectGrid" label="">
//                           <Form.Select
//                             aria-label="Default select example"
//                             value={filterYear}
//                             onChange={handleYearFilterChange}
//                           >
//                             <option value="">Year</option>
//                             {generateYears().map((year, index) => (
//                               <option key={index} value={year}>{year}</option>
//                             ))}
//                           </Form.Select>
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} className='form-aling' id='reg-form-align'>
//                         <FloatingLabel controlId="floatingInputGrid" label="Keyword Search..">
//                           <Form.Control
//                             type="text"
//                             placeholder="Search"
//                             value={searchItem}
//                             onChange={handleSearchInputChange}
//                           />
//                         </FloatingLabel>
//                       </Col>
//                       <Col md={4} id='search-btn1' style={{ width: '250px', textAlign: 'center' }}>
//                         <button style={{ width: '100%' }} type="button" className="btn btn-outline-primary">Search</button>
//                       </Col>
//                     </Row>
//                   </div>
//                   <div className='overflow-table'>
//                     <Table striped bordered hover variant="light" id='draft-regulation-table'>
//                       <thead>
//                         <tr>
//                           <th className='table-no-aling'>SI.No.</th>
//                           <th>Description</th>
//                           <th>Year</th>
//                           <th>English</th>
//                           <th>Malayalam</th>
//                           <th>Gazatte</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {filteredData.map((data, index) => (
//                           <tr key={data.id}>
//                             <td className='table-no-aling'>{index + 1}</td>
//                             <td style={{ color: data.color }} dangerouslySetInnerHTML={{ __html: data.description }}></td>
//                             <td>{data.year}</td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.english} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.malayalam} target='blank'>view</Link>
//                               </div>
//                             </td>
//                             <td>
//                               <div className='ordrs-icon-aling'>
//                                 <Link to={data.gazette} target='blank'>view</Link>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </div>
//               </Tab>

//               <Tab eventKey="final" title="Final Regulations">
//                 <div className='dropdown-wrapper'>
//                   <Row>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Regulation Type</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select
//                         aria-label="Default select example"
//                         value={selectedFinalRegulationType}
//                         onChange={handleFinalRegulationTypeChange}
//                       >
//                         <option>All Regulations</option>
//                         {regulationTypes.map((type, index) => (
//                           <option key={index}>{type}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>

//                   <Row id='regulation-year'>
//                     <Form.Label column lg={2} id='regulation-label'>
//                       <h6>Year</h6>
//                     </Form.Label>
//                     <Form.Group as={Col} controlId="formGridState">
//                       <Form.Select
//                         aria-label="Default select example"
//                         value={filterYearFinal}
//                         onChange={handleYearFilterChangeFinal}
//                       >
//                         <option value="">Choose...</option>
//                         {generateYears().map((year, index) => (
//                           <option key={index} value={year}>{year}</option>
//                         ))}
//                       </Form.Select>
//                     </Form.Group>
//                   </Row>
//                   <button type="button" className="btn btn-outline-primary" id='search-btn-finalreg'>Submit</button>
//                 </div>

//                 {regulationTypes.map((type, index) => (
//                   <div className='final-reg-table' key={"div_" + index}>
//                     <h3 id='table-heading-final-regulations'>{type}</h3>
//                     <div className='table-responsive'>
//                       <Table striped bordered hover variant="light">
//                         <thead>
//                           <tr>
//                             <th className='table-no-aling'>SL.No.</th>
//                             <th>Description</th>
//                             <th>Year</th>
//                             <th>English</th>
//                             <th>Malayalam</th>
//                             <th>Gazatte</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {filteredFinalData
//                             .filter(data => (selectedFinalRegulationType === "All Regulations" || data.option === type) &&
//                               (filterYearFinal === "" || data.year === filterYearFinal))
//                             .sort((a, b) => b.year - a.year) // Sort by year in descending order
//                             .map((data, index) => (
//                               <tr key={data.id}>
//                                 <td className='table-no-aling'>{index + 1}</td>
//                                 <td dangerouslySetInnerHTML={{ __html: data.description }}></td>
//                                 <td>{data.year}</td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.english} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.malayalam} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                                 <td>
//                                   <div className='ordrs-icon-aling'>
//                                     <Link to={data.gazette} target='blank'>view</Link>
//                                   </div>
//                                 </td>
//                               </tr>
//                             ))}
//                         </tbody>
//                       </Table>
//                     </div>
//                   </div>
//                 ))}
//               </Tab>
//             </Tabs>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import APIService from '../../APIService';

import './regulationspage.css'; // Ensure your existing CSS file is imported here

export default function Regulationspage() {
  const regulationTypes = [
    "Tariff Regulations",
    "Conduct of Business Regulations",
    "CGRF and Ombudsman Regulations",
    "Licensing Regulations",
    "Renewable Energy Regulations",
    "Distribution Licensee Regulations",
    "Generating companies Regulations",
    "Connectivity and Open Access Regulations",
    "Electricity Supply Code and Amendments",
    "Electricity Grid Code and Amendments",
    "Compliance and Audit Regulations",
    "Administrative Regulations",
    "Miscellaneous Regulations",
  ];

  const [draftData, setDraftData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredFinalData, setFilteredFinalData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [filterYearFinal, setFilterYearFinal] = useState("");
  const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

  useEffect(() => {
    APIService.get('draft-regulations')
      .then((response) => {
        setDraftData(response.data);
        setFilteredData(response.data);
      });
  }, []);

  useEffect(() => {
    APIService.get('final-regulations')
      .then((response) => {
        setFinalData(response.data);
        setFilteredFinalData(response.data);
      });
  }, []);

  useEffect(() => {
    const filterDraftData = () => {
      const searchTerm = searchItem.toLowerCase();
      const filteredItems = draftData.filter((data) =>
        data.description.toLowerCase().includes(searchTerm) &&
        data.year.toLowerCase().includes(filterYear)
      );
      setFilteredData(filteredItems);
    };
    filterDraftData();
  }, [searchItem, filterYear, draftData]);

  useEffect(() => {
    const filterFinalData = () => {
      const filteredItems = finalData.filter((data) =>
        (selectedFinalRegulationType === "All Regulations" || data.option === selectedFinalRegulationType) &&
        (filterYearFinal === "" || data.year === filterYearFinal)
      ).sort((a, b) => b.year - a.year);
      setFilteredFinalData(filteredItems);
    };
    filterFinalData();
  }, [selectedFinalRegulationType, filterYearFinal, finalData]);

  const handleSearchInputChange = (e) => {
    setSearchItem(e.target.value);
  }

  const handleYearFilterChange = (e) => {
    setFilterYear(e.target.value);
  }

  const handleYearFilterChangeFinal = (e) => {
    setFilterYearFinal(e.target.value);
  }

  const handleFinalRegulationTypeChange = (e) => {
    setSelectedFinalRegulationType(e.target.value);
  }

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year.toString());
    }
    return years;
  }

  return (
    <div>
      <Container fluid className='right-to-information'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Regulations</h1>
            </div>
          </Col>
        </Row>
      </Container>
   
      <Container>
        <Row >
       
          <Col md={12}>
            <Tabs
            style={{marginBottom:'0'}}
              defaultActiveKey="draft"
              transition={false}
              id="noanim-tab-example"
             
            >
              <Tab eventKey="draft" title="Draft Regulations">
                <div className='archive-scroll'>
                <div className='draft-regulation-search-area'>
                    <Row className='reg-row' id='regulation-row'>
                      <Col style={{marginTop:'10px'}} md={4} id="year-tab1">
                      
                          <Form.Select
                            aria-label="Default select example"
                            value={filterYear}
                            onChange={handleYearFilterChange}
                          >
                            <option value="">Year</option>
                            {generateYears().map((year, index) => (
                              <option key={index} value={year}>{year}</option>
                            ))}
                          </Form.Select>
                        
                      </Col>
                      <Col style={{marginTop:'10px'}} md={4} className='form-aling' id='reg-form-align'>
                        <FloatingLabel controlId="floatingInputGrid" label="Keyword Search..">
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            value={searchItem}
                            onChange={handleSearchInputChange}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={4} id='search-btn1' style={{ textAlign: 'center',paddingTop:'0',marginTop:'10px' }}>
                        <button style={{ width: '100%' }} type="button" className="btn btn-outline-primary">Search</button>
                      </Col>
                    </Row>
                  </div>
                  <div className='overflow-table'  >
                    <Table style={{marginTop:'10px'}}   striped bordered hover variant="light" id='draft-regulation-table'>
                      <thead>
                        <tr>
                          <th className='table-no-aling'>Sl No</th>
                          <th>Description</th>
                          <th>Year</th>
                          <th>English</th>
                          <th>Malayalam</th>
                          <th>Gazatte</th>
                        </tr>
                      </thead>
                      <tbody >
                        {filteredData.map((data, index) => (
                          <tr key={data.id}>
                            <td className='table-no-aling'>{index + 1}</td>
                            <td style={{ color: data.color }} dangerouslySetInnerHTML={{ __html: data.description }}></td>
                            <td>{data.year}</td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={data.english} target='blank'>view</Link>
                              </div>
                            </td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={data.malayalam} target='blank'>view</Link>
                              </div>
                            </td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={data.gazette} target='blank'>view</Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab>

              {/* <Tab eventKey="final" title="Final Regulations">
                <div className='dropdown-wrapper'>
                
                  <div >
                    <div style={{display:'flex'}}>
                      <Form.Label column lg={2} id='regulation-label'>
                        <h6>Regulation Type</h6>
                      </Form.Label>
                      <Form.Group  as={Col} controlId="formGridState">
                        <Form.Select style={{width:'30%'}}
                          aria-label="Default select example"
                          value={selectedFinalRegulationType}
                          onChange={handleFinalRegulationTypeChange}
                        >
                          <option>All Regulations</option>
                          {regulationTypes.map((type, index) => (
                            <option key={index}>{type}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>

                  <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}  id='regulation-year'>
                    
                     <div style={{display:'flex',justifyContent:'center'}}>
                        <Form.Label column lg={2} id='regulation-label'>
                          <h6>Year</h6>
                        </Form.Label>
                        <Form.Group as={Col} controlId="formGridState">
                          <Form.Select
                          style={{width:'30%'}}
                            aria-label="Default select example"
                            value={filterYearFinal}
                            onChange={handleYearFilterChangeFinal}
                          >
                            <option value="">Choose...</option>
                            {generateYears().map((year, index) => (
                              <option key={index} value={year}>{year}</option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                     </div>
                    
                  </div>
                  
                 <div style={{display:'flex',justifyContent:'center'}}> <button type="button" className="btn btn-outline-primary " id='search-btn-finalreg'>Submit</button></div>

                </div> */}
   <Tab eventKey="final" title="Final Regulations">
  <div className="final-regulation-search-area">
    <Row className="reg-row" id="regulation-row">
      {/* Year Dropdown */}
      <Col md={4} style={{ marginTop: "10px" }} id="year-tab2">
        <Form.Select
          aria-label="Select Year"
          value={filterYearFinal}
          onChange={handleYearFilterChangeFinal}
        >
          <option value="">Year</option>
          {generateYears().map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </Form.Select>
      </Col>

      {/* Regulation Type Dropdown */}
      <Col md={4} style={{ marginTop: "10px" }} className="form-align" id="reg-form-align-final">
        <Form.Select
          aria-label="Select Regulation Type"
          value={selectedFinalRegulationType}
          onChange={handleFinalRegulationTypeChange}
        >
          <option>All Regulations</option>
          {regulationTypes.map((type, index) => (
            <option key={index}>{type}</option>
          ))}
        </Form.Select>
      </Col>

      {/* Submit Button */}
      <Col
        md={4}
        id="search-btn2"
        style={{  textAlign: "center", paddingTop: "0", marginTop: "10px" }}
      >
        <button style={{ width: "100%",height:'42px' }} type="button" className="btn btn-outline-primary">
          Submit
        </button>
      </Col>
    </Row>
  </div>

  {/* Final Regulation Tables */}
  {regulationTypes.map((type, index) => (
    <div
      style={{ marginTop: "0px" }}
      className="final-reg-table"
      key={"div_" + index}
    >
      <h3 id="table-heading-final-regulations">{type}</h3>
      <div className="table-responsive">
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th className="table-no-aling">Sl No</th>
              <th>Description</th>
              <th>Year</th>
              <th>English</th>
              <th>Malayalam</th>
              <th>Gazette</th>
            </tr>
          </thead>
          <tbody>
            {filteredFinalData
              .filter((data) => data.option === type)
              .map((data, idx) => (
                <tr key={data.id}>
                  <td className="table-no-aling">{idx + 1}</td>
                  <td
                    style={{ color: data.color }}
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                  ></td>
                  <td>{data.year}</td>
                  <td>
                    <div className="ordrs-icon-aling">
                      <Link to={data.english} target="blank">
                        View
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="ordrs-icon-aling">
                      <Link to={data.malayalam} target="blank">
                        View
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="ordrs-icon-aling">
                      <Link to={data.gazette} target="blank">
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  ))}
</Tab>


            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
