// import React, { useState, useEffect } from 'react';
// import { Col, Container, Row } from 'react-bootstrap';
// import './search.css';
// import Marqueean from "react-fast-marquee";
// import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
// import Form from 'react-bootstrap/Form';
// import APIService from '../../../APIService'; // Import your API service

// export default function Search() {
//   const [searchQuery, setSearchQuery] = useState([]);
//   const [announcements, setAnnouncements] = useState([]);

//   useEffect(() => {
//     // Fetch announcements from the API
//     APIService.get("announcement").then((response) => {
//       setAnnouncements(response.data);
//     }).catch((error) => {
//       console.error("Error fetching announcements:", error);
//     });
//   }, []);

//   const handleSearch = () => {
//     if (searchQuery.length > 0) {
//       window.location.href = `/search_results?query=${searchQuery}`;
//     }
//   }

//   return (
//     <Container id='search'>
//       <Row>
//         <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} sm={12} lg={4} md={4}>
//           <div className='Announcements'>
//             <div className='Announcements-icon'>
//               <img className='Announcements-icon-img' src={require('../../../img/Announcements-icon.png')} alt="Announcements Icon" />
//             </div>
//             <div>
//               <div className='Announcements-text'>
//                 <Marqueean>
//                   {announcements.map((announcement) => (
//                     <span key={announcement.id}>{announcement.title}</span>
//                   ))}
//                 </Marqueean>
//               </div>
//             </div>
//           </div>
//         </Col>
//         <Col sm={12} lg={4} md={4} className='form-aling2' id="search-area">
//           <FloatingLabelSerch style={{ left: '0' }} className='Search-aling2' label="Keyword Search.." >
//             <Form.Control
//               placeholder="Enter Keyword to Search"
//               type="search"
//               id='home-search-bar'
//               onChange={e => setSearchQuery(e.target.value)}
//             />
//           </FloatingLabelSerch>
//         </Col>
//         <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} sm={12} lg={3} md={3}>
//           <button
//             id='home-search-btn'
//             type="button"
//             className="btn btn-outline-primary"
//             onClick={handleSearch}
//           >
//             Search
//           </button>
//         </Col>
//       </Row>
//     </Container>
//   );
// }
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import APIService from '../../../APIService';
import './search.css';

export default function Search() {
  const [announcements, setAnnouncements] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Fetch announcements from the API
    APIService.get("announcements")
      .then(response => {
        setAnnouncements(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the announcements!", error);
      });
  }, []);

  const handleSearch = () => {
    if (searchQuery.trim().length > 0) {
      window.location.href = `/search_results?query=${searchQuery}`;
    }
  };

  return (
    <Container style={{marginTop:'1rem',marginBottom:'0'}} id='search'>
      <Row>
      <div style={{fontSize:'20px'}} className='pulse2'>
            <div className='blink-hard2'><p style={{marginBottom:'0'}} className='blink-text'> new</p></div>
          </div>
        <Col
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',padding:'0' }}
          sm={12} lg={12} md={12}>
           
          <div className='Announcements'>
         
            <div className='Announcements-icon'>
              {/* <img className='Announcements-icon-img' src={require('../../../img/Announcements-icon.png').default} alt="Announcements Icon" /> */}
              <img className='Announcements-icon-img' src={require('../../../img/Announcements-icon.png')} />
            </div>
            <div>
              <div className='Announcements-text'>
                <Marquee>
                  {announcements.map(announcement => (
                    <span key={announcement.id}>
                      <a href={announcement.url} style={{ color: 'inherit', textDecoration: 'none' }}>
                        {announcement.title}
                      </a>
                      {' , '}
                    </span>
                  ))}
                </Marquee>
              </div>
            </div>
          </div>
        </Col>
        {/* <Col sm={12} lg={4} md={4} className='form-aling2' id="search-area">
          <FloatingLabel className='Search-aling2' label="Keyword Search..">
            <Form.Control
              placeholder="Enter Keyword to Search"
              type="search"
              id='home-search-bar'
              onChange={e => setSearchQuery(e.target.value)}
            />
          </FloatingLabel>
        </Col>
        <Col
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          sm={12} lg={3} md={3}>
          <button
            id='home-search-btn'
            type="button"
            className="btn btn-outline-primary"
            onClick={handleSearch}
          >
            Search
          </button>
        </Col> */}
      </Row>
    </Container>
  );
}