import React, { useState, useEffect } from 'react';
import "./about.css";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

export default function About({ commission }) {

  // {
  //   commission.members.map((member, index) => (
  //     console.log(member)
  //   ))
  // }


  return (
  
    
            <Container className='mt-5' fluid style={{ backgroundColor: 'rgba(224, 244, 255, 0.793)' }}>
                <div>
                    <Row className="content" id="content">
                        <h3>About</h3>
                        <Col md={6} lg={6} >
                            <img id="image" src={commission.banner} alt="Banner" className="img-fluid" />
                            <p className="mt-4 ab-text">
                                {commission.description}
                            </p>
                            <Link to="/commission">
                                <button type="button" className="btn btn-outline-primary btn-sm" id="abt-btn">
                                    Read More
                                </button>
                            </Link>
                        </Col>
    
                        {commission.members && commission.members.map((member, index) => (
                            <Col xs={12} sm={6}  lg={2} id="staff-profiles" key={index}>
                                <Card id="card-aling-members">
                                    <Card.Img
                                        className="card-img-members"
                                        src={member.image}
                                        alt={member.heading}
                                    />
                                    <Card.Body id="card-body-members">
                                        <div className="card-text">
                                            <h5 style={{marginBottom:'0'}} className="member-names">{member.heading}</h5>
                                            <p style={{marginBottom:'0'}} className="card-descignation">{member.caption}</p>
                                            <Link to="/commission">
                                                <button type="button"
                                                    id="member-profile-btn"
                                                    className="btn btn-outline-primary btn-sm">
                                                    View Details
                                                </button>
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
        )
    }
    
       
    
    // </div>
  
