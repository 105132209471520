import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import APIService from '../../../../APIService';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export const Advices = () => {

  const [data, setData] = useState([]);
  const [filterYear, setFilterYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchItem, setSearchItem] = useState("");

  useEffect(() => {
    APIService.get('advice_to_gok')
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = data.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    const filteredItems = data.filter((data) =>
      data.added_on.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  // Generate dynamic years list starting from current year
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 2001; i--) {
    years.push(i);
  }

  return (
    <div>
      <Container fluid className='StateAdvisory'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Advices</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{marginTop:'20px'}}>
        <Row className='wrapper' id='other-docs-search-bar'>
          <Col md={4} id="archives-search-area">
          
              <Form.Select
                aria-label="Default select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {/* Render dynamic years options */}
                {years.map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </Form.Select>
       
          </Col>
          <Col md={4} className='form-aling'>
            <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange} />
            </FloatingLabelSerch>
          </Col>
          <Col md={4}>            <button type="button" className="btn btn-outline-primary" id='search-btn'>Search</button>
</Col>
        </Row>
      </Container>
      <Container style={{marginTop:'10px'}}>
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th style={{width:'50px'}}>Sl No</th>
              <th>Title</th>
              <th>Date</th>
              <th style={{textAlign:'center'}}>Download</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((data, index) => (
              <tr key={data.id}>
                <td style={{textAlign:'center'}}>{index + 1}</td>
                <td>{data.title}</td>
                <td>{data.added_on}</td>
                <td>
                  <div className='ordrs-icon-aling'>
                    <Link to={data.file}>View</Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  )
}