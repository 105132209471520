import React, { useState, useEffect } from 'react';
import './quicklinks.css';
import { LinkTabs } from './LinkTabs';

export default function QuikLinks() {
    return (
        <div>
            <div style={{marginTop:'-40px'}} className='container'>
                <h3 >Quick Links</h3>
            </div>
            <LinkTabs />
        </div>
    )
}