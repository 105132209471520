import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import TabArchives from 'react-bootstrap/Tab';
import TabsTabArchives from 'react-bootstrap/Tabs';
import { Link } from "react-router-dom";
import './reports.css';
import APIService from '../../APIService';

export default function Reports() {

  const reportTypes = [
    "Annual Report",
    "Administrative Report",
    "Financial Report",
    "Other Report",
  ];

  const [data, setData] = useState([]);

  useEffect(() => {

    APIService.get('reports')
      .then((response) => {
        // console.log(response.data);
        setData(response.data);
      })
  }, []);

  return (
    <div>
      <Container fluid className='right-to-information' >
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Reports</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="report-tabs">
        <TabsTabArchives
          defaultActiveKey={reportTypes[0]}
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
        >
          {reportTypes.map((type, index) => (
            <TabArchives
              eventKey={type}
              title={type}
              key={"tab_" + index}
            >
              <div >
              <Table striped bordered hover variant="light">
  <thead>
    <tr>
      <th style={{ width: '50px' }}>Sl No</th>
      <th>Title</th>
      <th style={{ width: '100px', textAlign: 'center' }}>Year</th>
      <th style={{ width: '100px', textAlign: 'center' }}>Download</th>
    </tr>
  </thead>
  <tbody>
    {data
      .filter((report) => report.report === type)
      .map((report, index) => (
        <tr key={report.id}>
          <td style={{ textAlign: 'center' }}>{index + 1}</td>
          <td>{report.title}</td>
          <td style={{ textAlign: 'center', width: '100px' }}>{report.year}</td>
          <td style={{ textAlign: 'center', width: '100px' }}>
            <div className="ordrs-icon-aling">
              <Link to={report.file} target="_blank">
                View
              </Link>
            </div>
          </td>
        </tr>
      ))}
  </tbody>
</Table>

              </div>
            </TabArchives>
          ))}

        </TabsTabArchives>
      </div>
    </div>
  )
}
