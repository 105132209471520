import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import './history.css';
import APIService from '../../APIService';
import Card from 'react-bootstrap/Card';

export default function History() {
  const [data, setData] = useState([]);

  useEffect(() => {
    APIService.get('history')
      .then((response) => {
        setData(response.data);
      });
  }, []);

  return (
    <div>
      <Container fluid className="right-to-information">
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">History</h1>
            </div>
          </Col>
        </Row>
      </Container>
      
      <Container id="history">
        <h2>Former Chairpersons</h2>
        <Row className='mt-4'>
          {data.map((dataItem, index) => (
            <Col className='mt-3' style={{display:'flex',justifyContent:'center',}} key={index} xs={12} sm={6} md={4} lg={3}>
              <Card style={{width:'100%',height:'100%'}}>
               
                  
                    
                      <Card.Img className="card-img" src={dataItem.image} alt="Profile" />
                      <Card.Body>
                        <Card.Title style={{textAlign:'center'}}>{dataItem.name}</Card.Title>
                        <Card.Text>{dataItem.tenure}</Card.Text>
                        </Card.Body>
                    
                 
                
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
