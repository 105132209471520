import React, { useState, useEffect } from 'react';
import './Commission.css';
import { Col, Container, Row } from 'react-bootstrap';
import CommissionCard from './CommissionCards/CommissionCard';
import APIService from '../../APIService';

export default function Commission() {

  const [commissionMembers, setCommissionMembers] = useState([]);
  const [image, setImageLink] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [functions, setFunctions] = useState("");

  useEffect(() => {
    APIService.get('commission')
      .then((response) => {
        setImageLink(response.commission_image);
        setHeading(response.commission_heading);
        setDescription(response.commission_description);
        setFunctions(response.commission_functions);
      });

    APIService.get('commission_members').then((response) => {
      setCommissionMembers(response.data);
    });
  }, []);

  return (
    <div style={{marginBottom:"30px"}}>
      <Container>
        <Row className='mt-4'>
          <Col md={6}>
            <h2>
              {heading}
            </h2>
            <div dangerouslySetInnerHTML={{ __html: description }} className='about-pag-cont' />
          </Col>
          <Col md={6}>
            <img className='About-page-img' src={image} />
          </Col>
        </Row>
        <div className='member-section'>
          <h2>Members</h2>
          <p className='commission-members-heading'>The Commission consists of the following Members:</p>
        </div>
      </Container>
      <CommissionCard members={commissionMembers} />
      <Container>
        <Row className='mt-5'>
          <Col md={12}>
            <h2>Functions </h2>
            <div className='commission-function-text' dangerouslySetInnerHTML={{ __html: functions }} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
