import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import FloatingLabelSerch from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Table from 'react-bootstrap/Table';
import './quotation.css';
import { Link } from "react-router-dom";
import APIService from '../../APIService';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

export default function Quotations() {

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    APIService.get('quotations')
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = data.filter((data) =>
      data.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);

    const filteredItems = data.filter((data) =>
      data.added_on.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredData(filteredItems);
  }

  // Generate years dynamically from current year to 2001
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year);
    }
    return years;
  }

  return (
    <div>
      <Container fluid className="StateCoordination">
        <Row>
          <Col  md={12}>
            <div className="organisation-img">
              <h1 className="organisation-head">Quotations</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container  style={{marginTop:'20px'}} >
        <Row className='wrapper'>
          <Col className='mt-2' md={4} id="archives-search-area">
           
              <Form.Select
                aria-label="Default select example"
                value={filterYear}
                onChange={handleYearFilterChange}
              >
                <option value="">Year</option>
                {generateYears().map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </Form.Select>
           
          </Col>
          <Col md={4} className='form-aling mt-2' id='archives-form'>
            <FloatingLabelSerch className='Search-aling' controlId="floatingInputGrid" label="Keyword Search..">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchItem}
                onChange={handleSearchInputChange}
              />
            </FloatingLabelSerch>
            {/* <button type="button" className="btn btn-outline-primary" id='search-btn'>Search</button> */}

          </Col>
          <Col className='mt-2' md={4} id='search-btn1'  style={{textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
        </Row>
      </Container>
      <Container style={{marginTop:'10px'}}  className="qutation-table">
        <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th style={{width:'50px'}}>Sl No</th>
              <th>Title</th>
              <th style={{width:'100px'}}>Date</th>
              <th style={{textAlign:'center'}}>Download</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((data, index) => (
              <tr key={data.id}>
                <td style={{textAlign:'center'}}>{index + 1}</td>
                <td>{data.title}</td>
                <td>{data.added_on}</td>
                <td> <div className='ordrs-icon-aling'>
                <Link to={data.file} target='blank'>View</Link> </div></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  )
}
