import React, { useState, useEffect } from 'react';
import Baner from '../../Componets/Home/Baner/Baner';
import Search from '../../Componets/Home/Search/Search';
import About from '../../Componets/Home/About/About';
import WhatsNew from '../../Componets/Home/WhatsNew/WhatsNew';
import NewsGallery from '../../Componets/Home/NewsGallery/NewsGallery';
import AllLinks from '../../Componets/Home/AllLinks/AllLinks';
import APIService from '../../APIService';

export default function Home() {

  const [petitions, setPetitions] = useState([]);
  const [whatsNew, setWhatsNew] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [news, setNews] = useState([]);
  const [commission, setCommission] = useState([]);

  useEffect(() => {
    APIService.get('home').then((response) => {
      const data = response.data;
      setPetitions(data.petitions);
      setWhatsNew(data.whats_new);
      setAllOrders(data.all_orders);
      setGallery(data.gallery);
      setNews(data.news);
      setCommission(data.commission);
    })
  }, []);

  const [otherLinks, setOtherLinks] = useState([]);

  useEffect(() => {
    APIService.get('other_links')
      .then((response) => {
        setOtherLinks(response.data);
      })
  }, []);
  return (
    <div className='Home'>
            <Search petitions={petitions} />
      <Baner />

      <WhatsNew regulations={whatsNew} orders={allOrders} />
      <About commission={commission} />
      <NewsGallery gallery={gallery} news={news} />
      <AllLinks links={otherLinks} />
    </div>
  )
}
